<template>
  <v-select
    outlined
    :items="options"
    :label="field.text"
    v-model="value"
    item-text="text"
    item-value="value"
    append-icon="mdi-chevron-down"
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    value: {
      get () {
        return get(this.data, this.field.value, true)
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value })
      }
    },
    options () {
      const labels = this.field.name === 'active'
        ? ['aktywny', 'nieaktywny'] : ['Tak', 'Nie']
      return labels.map((text, index) => {
        return { text, value: index % 2 === 0 }
      })
    }
  }
}
</script>
